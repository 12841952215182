<template>
  <div class="content-main" :style="{backgroundImage: 'url(' + bg + ')', backgroundSize: 'cover'}">
    <div class="page">
      <div>
        <div class="top_menu">
          <div class="icon-main">
            <img :src="appIcon" class="app-icon">
            面具约会
          </div>
          <span class="content-us" @click="contentUs">联系我们</span>
        </div>
        <div class="tuiguang-content">
          <img :src="phoneCtn" class="left-phone-ctn">
          <div class="right-main">
            <div class="tuiguang-content-title">面具约会</div>
            <div class="tuiguang-content-subtitle">真人认证&nbsp;&nbsp;真诚交友</div>
            <div style="display: flex; align-items: center; margin-top: 60px;">
              <div style="margin-right: 40px;">
                <div class="down-btn phone-down">
                  <span>手机扫码下载</span>
                  <img :src="downLine" class="down-line">
                </div>
                <div class="down-btn down-pc" style="margin-top: 30px;" @click="downloadAndroid">
                  <span>电脑本地下载</span>
                </div>
              </div>
              <div class="tuiguang-content-qrcode">
                <img :src="qrcode" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="beian">
        网站备案/许可证号：
        <span class="beian-url" @click="openUrl">粤ICP备2021037536号</span>
        违法不良信息举报邮箱：lqs888lqs@163.com
      </div>
    </div>
    <div class="hide" v-show="show"></div>
    <div class="content-back" v-show="show" @click="hideCus">
      <div class="cus-content" @click="hideCus">
        <span class="cus-title">公司介绍</span>
        <div class="cus-text">
          广州龙骑士网络有限公司，是一家移动互联网公司。<br/>
          面具约会app是一款主打真实认证真诚交友的APP，希望为用户提供真诚的交友氛围。
        </div>
        <span class="cus-title">联系我们</span>
        <div class="cus-text">lqs888lqs@163.com</div>
      </div>
    </div>
  </div>
</template>

<script>
import bg from '@/assets/images/bg_tuiguang.jpeg'
import phoneCtn from '@/assets/images/phone_content.jpg'
import phoneFrame from '@/assets/images/phone_frame.png'
import qrcode from '@/assets/images/qrcode.png'
import android from '@/assets/images/ic_android.png'
import ios from '@/assets/images/ic_ios.png'
import appIcon from '@/assets/images/app_icon.png'
import downLine from '@/assets/images/down-line.png'
// import fileSaver from 'file-saver'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'
export default {
    name: "WebDownload",
    data() {
        return {
            bg,
            phoneCtn,
            phoneFrame,
            qrcode,
            android,
            ios,
            appIcon,
          downLine,
          show: false
        }
    },
    methods: {
        openUrl() {
            window.open("https://beian.miit.gov.cn/", '_blank')
        },
        downloadAndroid() {
            saveAs(this.apkUrl, 'mianjuyuehui.apk')
        },
      contentUs() {
        this.show = true
      },
      hideCus() {
          this.show = false
      }
    },
    computed: {
        ...mapState({
            apkUrl: state => state.apkUrl,
        }),
    },
    mounted() {
      const u = navigator.userAgent;
      this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (this.isiOS || this.isAndroid) {
        window.location.href = 'https://www.mianjuyuehui.net/webs/down-page'
      }
    }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.hide {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
  background-color: black;
  opacity: 0.6;
  width: 100vw;
  height: 100vh;
}
.cus-text {
  font-size: 20px;
  font-weight: 500;
  margin: 25px 0;
}
.content-back {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.top_menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}
.icon-main {
  font-size: 26px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.app-icon {
  width: 68px;
  height: 68px;
  margin-right: 12px;
}
.content-main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.page {
  width: 1200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-us {
  font-size: 20px;
  color: #FFFFFF;
  cursor: pointer;
}
.cus-content {
  width: 640px;
  height: 457px;
  background-color: white;
  border-radius: 20px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.tuiguang-content{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 110px;
}
.cus-title {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 500;
  color: #7450CA;
}
.phone-down {
  position: relative;
}
.down-btn {
  width: 160px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 31px;
  border: 2px solid #FFFFFF;
  color: #764FCA;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-main {
  height: 716px;
  text-align:left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 120px;
}
.left-phone-ctn {
  width: 340px;
  height: 716px;
  border-radius: 30px;
}
.down-pc{
  cursor: pointer;
}
.beian {
  margin-top: 85px;
  font-size: 20px;
  color: white;
  padding-bottom: 30px;
  &-url {
    color: #f18989;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.down-line {
  width: 48px;
  height: 18px;
  position: absolute;
  top: 22px;
  margin-top: -9px;
  right: -40px;
}
.tuiguang {
    &-content {
        &-qrcode {
            width: 150px;
            height: 150px;
            border-radius: 10px;
            box-sizing: border-box;
            border: 2px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
                width: 130px;
                height: 130px;
            }
        }
        &-btn {
            width: 200px;
            height: 48px;
            background: linear-gradient(270deg, #C479DE 0%, #7456CB 100%);
            box-shadow: 0px 4px 6px 0px rgba(121, 86, 203, 0.2);
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
            >img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
        &-title {
          font-size: 60px;
          font-weight: 600;
          color: #FFFFFF;
        }
        &-subtitle {
          margin-top: 30px;
          font-size: 42px;
          font-weight: 600;
          color: #FFFFFF;
        }
    }
}
</style>
